import React, { useEffect } from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import axios from 'axios'
import {Helmet} from "react-helmet"
import {Button,Modal} from 'react-bootstrap'

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FieldInput, { MaskedInput, FieldRadio, FieldSelect2, FileInput } from '../../helpers/inputs.js'

import {Header} from '../common/header'
import Menu from '../common/menu'
import Footer from '../common/footer'

import Loading from '../common/loading'

import {getcampanha, registercampain, getDocHistory} from '../../helpers/actions/campanhas'
import {openModal} from '../../helpers/actions/infos'

function RenderHistory(historicos){
	return (
		historicos.map((history, index) => {
			return (
				<div key={index} className={`alert alert-${history.class} text-center mb-2`}>
					<p style={{ color: history.color_text }} className="mb-0">Seu documento foi {history.text} em {history.formatted_date}</p>
					{(history.motivo !== '') ? (
						<p style={{ color: '#ffffff' }} className="mb-0">
							<strong>Motivo:</strong> {history.motivo}
						</p>
					) : (
						<>
						</>
					)}
				</div>
			)
		})
	)
}

function RenderModal(props){
	const { openModal } = props
	
	return (
		<>
			<Modal 
				show={props.modalShow}
				size="lg"
				id="modalHistory"
      			aria-labelledby="contained-modal-title-vcenter"
      			onHide={() => openModal(false)}
      			centered>
		    	<Modal.Header closeButton>
		        	<Modal.Title>Histórico do Documento</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<div>
					{props.docHistory.length > 0 ? (
						<>
							{RenderHistory(props.docHistory)}
						</>
					) : (
						<>
							<Loading />
						</>
					)}
		        	</div>
		        </Modal.Body>
		        <Modal.Footer>
		        	<Button variant="secondary" onClick={() => openModal(false)}>
		            	Fechar
		          	</Button>
		        </Modal.Footer>
		   	</Modal>
		</>
	)	
}

function RenderDocs(docs, names, carteirinhaData, props){
	const documentos = JSON.parse(docs)
	const docArray = Object.entries(documentos)

	const {openModal, getDocHistory} = props

	const getHistory = doc => {
		openModal(!props.modalShow)
		setTimeout(function(){
			getDocHistory(props.userToken, props.match.params['cliente'], doc)
		}, 1500)
	}

	return (
		docArray.map((documento, index) => {
			var docData
			if(carteirinhaData && carteirinhaData.documentos.length > 0){
				docData = carteirinhaData.documentos.filter(doc => doc.documento === documento[0])
			}

			if(docData !== null && docData !== undefined && docData.length > 0){
				console.log(docData[0])

				if (parseInt(docData[0].status) === 1){
					return (
						<>
							<div className="form-group row mt-2">
				                <label className="col-12 col-lg-4 my-2 control-label">{names[documento[0]]}</label>
				                <div className="col-12 col-lg-8">
									<div className="alert alert-success mb-1">
										<small>Seu documento de {names[documento[0]]} foi aprovado.</small>
									</div>
									<div className="text-left">
										<a href="#" onClick={() => getHistory(docData[0].id)}><i className="fas fa-history mr-2"></i>Ver histórico</a>
									</div>
								</div>
							</div>
							<hr />
						</>
					)
				} else if(parseInt(docData[0].status) === 0) {
					return (
						<>
							<div className="form-group row mt-2">
				                <label className="col-12 col-lg-4 my-2 control-label">{names[documento[0]]}</label>
				                <div className="col-12 col-lg-8">
				                	<div className="alert alert-danger mb-1">
				                		<small>
											<p className="mb-0">Seu documento de {names[documento[0]]} foi reprovado!</p>
											<p className="mb-0"><strong>Motivo:</strong>{docData[0].aprovacoes[0].motivo}</p>
										</small>
									</div>
									<div className="text-left mb-4">
										<a href="#" onClick={() => getHistory(docData[0].id)}><i className="fas fa-history mr-2"></i>Ver histórico</a>
									</div>

				                    <Field
										name={`documentos[${documento[0]}]`}
										accept="image/*,application/pdf"
										capture="user"
										component={ FileInput }
									/>
				                </div>
				            </div>
				            <hr />
						</>
					)
				} else {
					return (
						<>
							<div className="form-group row mt-2">
				                <label className="col-12 col-lg-4 my-2 control-label">{names[documento[0]]}</label>
				                <div className="col-12 col-lg-8">
				                	<div className="alert alert-warning mb-1">
										<small>Seu documento de {names[documento[0]]} está em análise</small>
									</div>
									<div className="text-left mb-4">
										<a href="#" onClick={() => getHistory(docData[0].id)}><i className="fas fa-history mr-2"></i>Ver histórico</a>
									</div>

				                    <Field
										name={`documentos[${documento[0]}]`}
										accept="image/*,application/pdf"
										capture="user"
										component={ FileInput }
									/>
				                </div>
				            </div>
				            <hr />
						</>
					)
				}
			} else {
				return (
					<div className="form-group row">
		                <label className="col-12 col-lg-4 my-2 control-label">{names[documento[0]]}</label>
		                <div className="col-12 col-lg-8">
		                    <Field
								name={`documentos[${documento[0]}]`}
								accept="image/*,application/pdf"
								capture="user"
								component={ FileInput }
							/>
		                </div>
		            </div>
				)
			}
		})
	)
}

function RenderForm(props){
	const {handleSubmit, registercampain} = props

	return (
		<form className="form-horizontal my-4" onSubmit={handleSubmit(value => registercampain(props.userToken, props.match.params['cliente'], props.userData.id, props.match.params['id'], value))}>
			<h4>Dados Pessoais</h4>

			<Field
				name="action"
				type="hidden"
				component={FieldInput}
			/>		
			<div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Nº da Identidade (RG)</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="nr_identidade"
						placeholder="Digite seu número de identidade (RG)"
						type="text"
						mask="99.999.999-9"
						component={ MaskedInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Telefone Fixo</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="telefone"
						placeholder="Digite seu número de telefone fixo"
						type="text"
						mask="(99) 9999-9999"
						component={ MaskedInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Celular</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="celular"
						placeholder="Digite seu número de telefone celular"
						type="text"
						mask="(99) 9 9999-9999"
						component={ MaskedInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Nome da Mãe</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="nome_mae"
						placeholder="Digite o nome de sua mãe"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Nome do Pai</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="nome_pai"
						placeholder="Digite o nome de seu pai"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Trabalha?</label>
                <div className="col-12 col-lg-8 text-left">
                	<div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="trabalha"
								id="option-trabalha-0"
								value="0"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-trabalha-0">Não</label>
			            </div>
			        </div>

			        <div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="trabalha"
								id="option-trabalha-1"
								value="1"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-trabalha-1">Sim</label>
			            </div>
			        </div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Se trabalha, qual o nome da empresa?</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="nome_empresa"
						placeholder="Digite o nome da empresa caso trabalhe"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Quantas pessoas residem em sua residência?</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="qtos_residem"
						placeholder="Informe quantas pessoas residem com você"
						type="number"
						min="1"
						component={ FieldInput }
					/>
                </div>
            </div>

			<div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">A residência é alguada?</label>
                <div className="col-12 col-lg-8 text-left">
                	<div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="aluguel"
								id="option-aluguel-0"
								value="0"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-aluguel-0">Não</label>
			            </div>
			        </div>

			        <div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="aluguel"
								id="option-aluguel-1"
								value="1"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-aluguel-1">Sim</label>
			            </div>
			        </div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Quantas pessoas trabalham?</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="qtos_trabalham"
						placeholder="E quantas pessoas trabalham?"
						type="number"
						min="1"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Renda Familiar</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="renda_familiar"
						placeholder="Informe sua renda familiar"
						type="number"
						min="1"
						step="1"
						component={ FieldInput }
					/>
                </div>
            </div>

            <hr />

            <h4>Meu Endereço</h4>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Logradouro (Rua, Avenida)</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="logradouro"
						placeholder="Digite seu endereço (Rua, Av, etc)"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Número</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="numero"
						placeholder="Digite o número do endereço"
						type="number"
						min="1"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Complemento</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="complemento"
						placeholder="Complemento (caso haja)"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Bairro</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="bairro"
						placeholder="Bairro"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <hr />

            <h4>Informações para Emissão da Carteirinha</h4>
			<div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Cidade</label>
                <div className="col-12 col-lg-8">
                	<div className="d-block w-100">
	                    <Field
							name="cidade_id"
							options={props.campanha.cidades}
							placeholder="Selecione a Cidade"
							className="w-100"
							component={ FieldSelect2 }
						/>
					</div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Escola/Faculdade</label>
                <div className="col-12 col-lg-8">
                    <div className="d-block w-100">
	                    <Field
							name="instituicao_id"
							options={props.campanha.instituicoes}
							placeholder="Selecione a Escola/Faculdade"
							className="w-100"
							component={ FieldSelect2 }
						/>
					</div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Turno</label>
                <div className="col-12 col-lg-8 text-left">
                	<div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="turno"
								id="option-turno-manha"
								value="manha"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-turno-manha">Manhã</label>
			            </div>
			        </div>

			        <div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="turno"
								id="option-turno-tarde"
								value="tarde"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-turno-tarde">Tarde</label>
			            </div>
			        </div>

			        <div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="turno"
								id="option-turno-noite"
								value="noite"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-turno-noite">Noite</label>
			            </div>
			        </div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Tipo do Curso</label>
                <div className="col-12 col-lg-8 text-left">
                	<div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="tipo_curso"
								id="option-tipo-curso-tecnico"
								value="tecnico"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-tipo-curso-tecnico">Técnico</label>
			            </div>
			        </div>

			        <div className="my-2">
			            <div className="custom-control custom-radio">
			            	<Field
								name="tipo_curso"
								id="option-tipo-curso-universitario"
								value="universitario"
								className="custom-control-input"
								type="radio"
								component={ FieldRadio }
							/>
			                <label className="custom-control-label" for="option-tipo-curso-universitario">Universitário</label>
			            </div>
			        </div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Curso</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="curso"
						placeholder="Informe o curso que realiza"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Período/Série/Módulo</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="serie"
						placeholder="Informe em qual período, série ou módulo que se encontra"
						type="text"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Início do Curso</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="inicio_curso"
						placeholder="Quando iniciou o curso?"
						type="date"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Término do Curso</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="final_curso"
						placeholder="Quando iniciou o curso?"
						type="date"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Horário de Entrada</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="hr_entrada"
						placeholder="Horário de entrada"
						type="time"
						component={ FieldInput }
					/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Horário de Saída</label>
                <div className="col-12 col-lg-8">
                    <Field
						name="hr_saida"
						placeholder="Horário de Saída"
						type="time"
						component={ FieldInput }
					/>
                </div>
            </div>

            <hr />
            <h4>Anexar Documentos</h4>
            <div className="form-group row">
                <label className="col-12 col-lg-4 my-2 control-label">Foto para a Carteirinha (foto 3x4)</label>
                <div className="col-12 col-lg-8">
                	<div className="mb-2">
                		<img src={props.userData.avatarFiltered} style={{ width: '80px' }} />
                	</div>

                    <Field
						name="avatar"
						accept="image/*"
						capture="user"
						component={ FileInput }
					/>
                </div>
            </div>

            {RenderDocs(props.campanha.documentos, props.campanha.documentosName, props.carteirinhaData, props)}
            {RenderModal(props)}
            <hr />
            <div dangerouslySetInnerHTML={{ __html:props.registerError }} />
            <div className="form-group mb-0 row">
                <div className="col-12 mt-2">
                    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                    	<span dangerouslySetInnerHTML={{ __html:props.sendText }} />
                    </button>
                </div>
            </div>  
		</form>
	)
}

function RenderCampanha(props){
	console.log(props.carteirinha)
	console.log(props.carteirinhaData)
	return (
		<div className="card">
			<div className="card-body">
				<div className="text-center">
					<h4>{props.campanha.name}</h4>
					<p className="text-muted">
                    	Carteirinha válida de {props.campanha.formated_validade_inicio} à {props.campanha.formated_validade_fim}
                    </p>
                    <p className="text-muted">
                    	Campanha disponível de {props.campanha.formated_dt_inicio} à {props.campanha.formated_dt_fim}
                    </p>
					<div className="font-18 mt-2" dangerouslySetInnerHTML={{ __html:props.campanha.description }} />
					{(!props.carteirinhaData || (props.campanha.renovacao_id === props.carteirinhaData.campanha.id) || (props.carteirinha !== undefined && (props.carteirinhaData.campanha.id === props.campanha.id))) ? (
						<>
						{props.campanha.isValid ? (
							<div className="row justify-content-center">
								<div className="col-12 col-lg-6">
									{RenderForm(props)}
								</div>
							</div>
						) : (
							<div className="alert alert-danger">
								Esta campanha não está mais ativa
							</div>
						)}
						</>
					) : (
						<>
						{props.carteirinhaData.is_valid ? (
							<div className="alert alert-success">
								Sua carteirinha foi aprovada. Acesse a <a href={`/${props.match.params['cliente']}/`}>página inicial</a> para visualizar
							</div>
						) : (
							<>
							{props.carteirinhaData.transporte_campanha_id === parseInt(props.match.params['id']) ? (
								<div className="row justify-content-center">
									<div className="col-12 col-lg-6">
										<div className="alert alert-warning text-center mb-2">
											Seus dados estão sob análise para concessão da carteirinha de estudante.
										</div>
										{RenderForm(props)}
									</div>
								</div>
							) : (
								<div className="alert alert-danger">
									Você já está em uma campanha ativa!
								</div>
							)}
							</>
						)}
						</>
					)}
				</div>
			</div>
		</div>
	)
}

function Campanha(props){
	const breadcrumb = [props.clienteData.nome, 'Painel', 'Campanhas', 'Detalhes da Campanha']

	const {getcampanha} = props

	useEffect(() => {
		getcampanha(props.userToken, props.match.params['cliente'], props.match.params['id']);
	}, [])

	return (
		<div>
			<Header cliente={props.match.params['cliente']} titulo={`Bem-vindo, ${props.userData.name}`} icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />
			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu cliente={props.match.params['cliente']} />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	        				{props.campanha ? (
	        					<>
	        					{RenderCampanha(props)}
	        					</>
	        				) : (
	        					<Loading />
	        				)}
	                    	</div>
	                    </div>
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={`Sistema de Carteirinha`} />
	            </div>
	        </div>
		</div>
	)
}

Campanha = reduxForm({form: 'authCampanha'})(Campanha)
const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	clienteValido: state.cliente.isValid,
	carteirinhaData: state.carteirinha.carteirinhaData,
	campanha: state.campanhas.campanha,
	sendText: state.campanhas.sendText,
	registerError: state.campanhas.registerError,
	modalShow: state.campanhas.modalShow,
	loadDocs: state.campanhas.loadDocs,
	docHistory: state.campanhas.docHistory,
	initialValues: {
		nr_identidade: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.nr_identidade : '',
		logradouro: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.logradouro : '',
		numero: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.numero : '',
		complemento: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.complemento : '',
		bairro: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.bairro : '',
		cidade_id: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.cidade_id : '',
		telefone: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.telefone : '',
		celular: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.celular : '',
		nome_mae: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.nome_mae : '',
		nome_pai: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.nome_pai : '',
		trabalha: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.trabalha : '',
		nome_empresa: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.nome_empresa : '',
		qtos_residem: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.qtos_residem : '',
		renda_familiar: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.renda_familar : '',
		instituicao_id: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.instituicao_id : '',
		turno: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.turno : '',
		tipo_curso: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.tipo_curso : '',
		curso: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.curso : '',
		serie: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.serie : '',
		inicio_curso: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.inicio_curso : '',
		final_curso: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.final_curso : '',
		hr_entrada: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.hr_entrada : '',
		hr_saida: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.hr_saida : '',
		qtos_trabalham: (state.carteirinha.carteirinhaData) ? state.carteirinha.carteirinhaData.qtos_trabalham : '',
		action: (state.carteirinha.carteirinhaData) ? 'edit' : 'create'
	}
})
const mapDispatchToProps = dispatch => bindActionCreators({
	getcampanha,
	registercampain,
	openModal,
	getDocHistory
}, dispatch)

const conCampanha = connect(mapStateToProps, mapDispatchToProps)(Campanha)

export {conCampanha as campanha}