import React, {useEffect} from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import axios from 'axios'
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Menu from '../common/menu'
import Footer from '../common/footer'

import Loading from '../common/loading'

import RenderCampanhas from '../common/renderCampanhas'

import {getcampanhas, updatecarteirinha} from '../../helpers/actions/campanhas'


function RenderCarteirinha(props)
{
	return (
		<>
		<div className="row justify-content-center carteirinha">
			<div className="col-12 col-md-6">
				<div style={{ backgroundColor: '#033cfc', borderRadius: '10px', padding: '20px' }}>
					<div className="row">
						<div className="col-3">
							<div className="block">
								<img src={props.userData.avatarFiltered} className="mw-100 w-100" />
							</div>
						</div>
						<div className="col-8">
							<h4 className="mb-0 text-white">{props.clienteData.nome}</h4>
							<h3 className="mt-0 text-white">{props.userData.name}</h3>
							<h5 className="mt-0 text-white">{props.carteirinhaData.instituicao.name} - {props.carteirinhaData.instituicao.cidade.name}/{props.carteirinhaData.instituicao.cidade.uf}</h5>
							<h5 className="mt-0 text-white">{props.carteirinhaData.curso} - {props.carteirinhaData.serie} - {props.carteirinhaData.turno_formated}</h5>
							<div className="my-4">
								<p className="text-white pb-0">Carteirinha válida de {props.carteirinhaData.campanha.formated_validade_inicio} à {props.carteirinhaData.campanha.formated_validade_fim}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		{!!props.carteirinhaData.renovacao &&(
		<div className="row justify-content-center carteirinha">
			<div className="col-12 col-md-4 col-lg-3 mt-4">
				<a href={`/${props.match.params['cliente']}/campanha/${props.carteirinhaData.renovacao.id}`} className="btn btn-primary btn-block">
					Renovar carteirinha
				</a>
			</div>
		</div>
		)}
		</>
	)
}

function Campanhas(props){
	const {getcampanhas} = props
	useEffect(() => {
		getcampanhas(props.userToken, props.match.params['cliente'])
	}, [])
	
	if(props.campanhas){
		if(props.campanhas.length > 0){
			return(
				<div className="row">
					<RenderCampanhas {...props} />
				</div>	
			)
		} else {
			return(
				<>
					Não temos campanhas para exibir
				</>	
			)
		}
	} else {
		return(
			<Loading />
		)
	}
}

function handleConnectionChange(){
	var connected = true
	const condition = navigator.onLine ? 'online' : 'offline';
	if (condition === 'online') {
		const webPing = setInterval(
	  		() => {
	    		fetch('//google.com', {
	      			mode: 'no-cors',
	      		})
	    	.then(() => {
	    		clearInterval(webPing)
	    	}).catch(() => {connected = false} )
	  	}, 2000);
		return connected;
	}
	return connected
}

function handleCampanha(props){
	var message = "";
	return (
		<>
			<div dangerouslySetInnerHTML={{ __html:message }} />
			{props.carteirinhaData.is_valid ? (
				<>
					{RenderCarteirinha(props)}
				</>
			) : (
				<>
					<div className="alert alert-warning mb-2 text-center">
						Sua solicitação de carteirinha está sob análise. Acesse abaixo para acompanhar a solicitação. 
					</div>
					<div className="text-center">
						<a href={`/${props.match.params['cliente']}/campanha/${props.carteirinhaData.transporte_campanha_id}`} className="btn btn-primary">Acompanhar Solicitação</a>
					</div>
				</>
			)}
		</>
	)
}

function Home(props){
	const breadcrumb = [props.clienteData.nome, 'Painel', 'Início']
	const {updatecarteirinha} = props
	const isConnected = (props.toUpdate) ? handleConnectionChange() : navigator.onLine

	var message = ""
	useEffect(() => {
		if(isConnected){
			updatecarteirinha(props.userToken, props.match.params['cliente'])
		} else {
			message = "<div class='alert alert-danger mb-3 text-center'>Você está desconectado. Para atualizar as informações conecte à internet e atualize a página</div>"
		}
	}, [])

	return (
		<div>
			<Header cliente={props.match.params['cliente']} titulo={`Bem-vindo, ${props.userData.name}`} icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />
			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu cliente={props.match.params['cliente']} />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	                    	{(props.carteirinhaData) ? (
	                    		<>
	                    			{handleCampanha(props)}
	                    		</>
	                    	) : (
	                    		<>
	                    			{Campanhas(props)}
	                    		</>
	                    	)}
	                    	</div>
	                    </div>
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={`Sistema de Carteirinha`} />
	            </div>
	        </div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	clienteValido: state.cliente.isValid,
	carteirinhaData: state.carteirinha.carteirinhaData,
	campanhas: state.campanhas.campanhas,
	toUpdate: state.carteirinha.update
})
const mapDispatchToProps = dispatch => bindActionCreators({
	getcampanhas,
	updatecarteirinha
}, dispatch)

const conHome = connect(mapStateToProps, mapDispatchToProps)(Home)

export {conHome as home}