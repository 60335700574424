import axios from 'axios'

export function logout() {
	return dispatch => {
		dispatch([
			{ type: 'USER_TOKEN_VALIDATED', payload: false },
			{ type: 'CARTEIRINHA_LOGOUT', payload: false }
		])
	}
}

export function signin(cliente, values) {
	let formData = new FormData()
	formData.append('email', values.login)
	formData.append('password', values.password)
	formData.append('remember', values.remember)

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: '<div class="form-loader alert alert-secondary mb-1"><i class="fas fa-spinner fa-spin"></i></div>'}
		])

		axios.post(`${process.env.REACT_APP_URL}carteirinha/${cliente}/auth`, formData)
			.then(resp => {
				if(resp.data.error){
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				} else {
					if(resp.data.success){
						dispatch([
							{type: 'USER_FETCH_TOKEN', payload: resp.data.access_token},
							{type: 'USER_FETCHED', payload: resp.data.user},
							{type: 'SET_CARTEIRINHA', payload: resp.data.carteirinha}
						])
					} else {
						dispatch([
							{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
						])	
					}
				}
			})
			.catch(e => {
				dispatch([
					{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>'}
				])
			})
	}
}

export function signup(cliente, values) {
	let formData = new FormData()
	formData.append('name', values.nome)
	formData.append('email', values.email)
	formData.append('password', values.password)
	formData.append('password_confirmation', values.password_confirmation)

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: '<div class="form-loader alert alert-secondary mb-1"><i class="fas fa-spinner fa-spin"></i></div>'}
		])

		axios.post(`${process.env.REACT_APP_URL}carteirinha/${cliente}/register`, formData)
			.then(resp => {
				if(resp.data.success){
					dispatch([
						{type: 'USER_FETCH_TOKEN', payload: resp.data.access_token},
						{type: 'USER_FETCHED', payload: resp.data.user}
					])
				} else {
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				}
			})
			.catch(e => {
				dispatch([
					{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>'}
				])
			})
	}	
}