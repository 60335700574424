import React, { useEffect, useState } from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import axios from 'axios'
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FieldInput, { CustomCheckbox } from '../../helpers/inputs.js'

import { infos } from '../../helpers/actions/infos'
import { signin, logout } from '../../helpers/actions/login'

import {error as ErrorPage} from '../main/error'

function Logout(props) {
	const { logout } = props
	const cliente = props.match.params['cliente']

	if(props.clienteData)
		logout()

	return (
		<Redirect to={`/${props.match.params['cliente']}/login`} />
	)
}

function LoginScreen(props){
	const {handleSubmit, signin} = props
	const [alerts, setAlerts] = useState([])
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/${props.match.params['cliente']}/alertas`).then((data) => {
			if(data.data.success){
				setAlerts(data.data.alertas);
				console.log(alerts)
			}
		});
	}, [])

	useEffect(() => {
		console.log(alerts)
	}, [alerts])
	
	return (
		<div className="account-body">
			<Helmet>
                <title>{props.clienteData.nome} - Login</title>
                <link rel="shortcut icon" href={props.clienteData.logo_prefeitura} />
	            <link rel="apple-touch-icon" href={props.clienteData.logo_prefeitura} />
	            <link rel="apple-touch-icon" sizes="72x72" href={props.clienteData.logo_prefeitura} />
            </Helmet>
			<div className="row vh-100">
	            <div className="col-lg-3  pr-0">
	                <div className="card mb-0 shadow-none">
	                    <div className="card-body">
	                        
	                        <div className="px-3">
	                            <div className="media">
	                            	{(props.clienteData.logo_prefeitura > 0) ? (
	                            		<>
	                                	<a href={`/${props.match.params['cliente']}/`} className="logo logo-admin"><img src={ props.clienteData.logo_prefeitura } height="55" alt="logo" className="my-3" /></a>
	                                	</>
	                                ) : (
	                                	<>
	                                	</>
	                                )}
	                                <div className="media-body ml-3 align-self-center">                                                                                                                       
	                                    <h4 className="mt-0 mb-1">{ props.clienteData.nome }</h4>
	                                    <p className="text-muted mb-0">Faça seu login para continuar.</p>
	                                </div>
	                            </div>

								{!!alerts && (
									<>
									{alerts.map((alert,i) => (
										<div className={`alert alert-${alert.class} my-2`}>
											<i className="fas fa-exclamation-triangle mr-2"></i>
											{alert.text}
										</div>
									))}
									</>
								)}
	                            
	                            <form className="form-horizontal my-4" onSubmit={handleSubmit(value => signin(props.match.params['cliente'], value))}>
	    							<div className="form-group" id="formLoginLogin">
	                                    <label htmlFor="username">Matrícula ou E-mail</label>
	                                    <div className="input-group mb-3">
	                                        <div className="input-group-prepend">
	                                            <span className="input-group-text" id="basic-addon1"><i className="mdi mdi-account-outline font-16"></i></span>
	                                        </div>
	                                        <Field
												name="login"
												placeholder="Digite sua matrícula ou e-mail"
												type="text"
												component={ FieldInput }
											/>
	                                    </div>                                    
	                                </div>
	    
	                                <div className="form-group">
	                                    <label htmlFor="userpassword">Senha</label>
	                                    <div className="input-group mb-3">
	                                        <div className="input-group-prepend">
	                                            <span className="input-group-text" id="basic-addon2"><i className="mdi mdi-key font-16"></i></span>
	                                        </div>
	                                        <Field
												name="password"
												placeholder="Digite sua senha"
												type="password"
												component={ FieldInput }
											/>
	                                    </div>                                
	                                </div>
	    
	                                <div className="form-group row mt-4">
	                                    <div className="col-sm-6">
	                                        <div className="custom-control custom-checkbox">
	                                        	<Field
													name="remember"
													type="checkbox"
													className="custom-control-input"
													id="customControlInline"
													component={ CustomCheckbox }
												/>
	                                            <label className="custom-control-label" htmlFor="customControlInline">Lembrar Senha</label>
	                                        </div>
	                                    </div>
	                                    <div className="col-sm-6 text-right">
	                                        <a href={`/${props.match.params['cliente']}/registrar`} className="btn btn-primary btn-sm font-13"><i className="mdi mdi-plus"></i> Criar Conta</a> 
	                                    </div>
	                                </div>
	    
	                                <div className="form-group mb-0 row">
	                                    <div className="col-12 mt-2">
	                                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Fazer login <i className="fas fa-sign-in-alt ml-1"></i></button>
	                                    </div>
	                                </div>  

	                                {props.submitting ?
	                                	<div>Aguarde</div>
	                                :
	                                	<div dangerouslySetInnerHTML={{ __html: props.errorUser }}></div>
	                                }
	                            </form>
	                        </div>
	                    </div>
	                </div>
	            </div>
	            <div className="col-lg-9 p-0 d-flex justify-content-center">
	                <div className="accountbg d-flex align-items-center" style={{ backgroundImage: 'url('+props.clienteData.background+')' }}> 
	                    <div className="account-title text-white text-center">
	                    	{(props.clienteData.logo_prefeitura > 0) ? (
	                    		<>
	                        		<img src={props.clienteData.logo_prefeitura} alt="" className="thumb-sm" />
	                        	</>
	                        ) : (
	                        	<>
	                        	</>
	                        )}
	                        <h4 className="mt-3">Bem-vindo(a) ao { props.clienteData.nome }</h4>
	                        <div className="border w-25 mx-auto border-primary"></div>
	                        <h1 class="">Sistema de Carteirinhas</h1>
	                        <p className="font-14 mt-3">Sistema de Carteirinhas</p>
	                       	<p className="mt-3">Ainda não é registrado? <a href={`/${props.match.params['cliente']}/registrar`}>Crie sua conta aqui</a></p>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
	)
}

function Login(props){
	if(props.errorPage){
		return (
			<ErrorPage />
		)
	} else {
		if(props.userData !== undefined && props.userData !== null){
			return (
				<Redirect to={`/${props.match.params['cliente']}`} />
			)
		} else {
			if (props.clienteData){
				if(props.clienteData.slug === props.match.params['cliente']){
					return (
						<>
						{LoginScreen(props)}
						</>
					)
				} else {
					const {infos} = props

					const cliente = props.match.params['cliente']
					infos(cliente)

					return (
						<div className="account-body">
							<div className="row vh-100">
								<div className="col-12 d-flex justify-content-center">
									<div className="d-flex align-items-center"> 
										<div className="loader">Loading...</div>
									</div>
								</div>
							</div>
						</div>
					)	
				}
			} else {
				const {infos} = props

				const cliente = props.match.params['cliente']
				infos(cliente)

				return (
					<div className="account-body">
						<div className="row vh-100">
							<div className="col-12 d-flex justify-content-center">
								<div className="d-flex align-items-center"> 
									<div className="loader">Loading...</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		}
	}
}

Login = reduxForm({form: 'authForm'})(Login)
const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	token: state.profile.validToken,
	errorUser: state.profile.errorUser,
	clienteData: state.cliente.cliente,
	clienteValido: state.cliente.isValid,
	errorPage: state.cliente.redirectError
})
const mapDispatchToProps = dispatch => bindActionCreators({
	infos,
	signin,
	logout
}, dispatch)

const conLogin = connect(mapStateToProps, mapDispatchToProps)(Login)
const conLogout = connect(mapStateToProps, mapDispatchToProps)(Logout)

export {conLogin as login}
export {conLogout as logout}